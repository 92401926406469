import { ComponentType, MouseEvent, ReactNode } from 'react';

import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import SpinnerInline from 'components/Spinner/SpinnerInline/SpinnerInline';

import AmplifyAuth from 'lib/amplify/amplifyAuthClient';
import { useAuthContext } from 'lib/context/AuthContext';
import {
  getItem,
  setItem,
  SessionStorageKeys,
} from 'lib/sessionStorageWrapper/sessionStorageWrapper';

import AppleIcon from 'assets/icons/ic-apple.inline.svg';
import GoogleIcon from 'assets/icons/ic-google.inline.svg';

import styles from './SocialAuthButton.module.scss';

export enum CognitoHostedUIIdentityProvider {
  Amazon = 'LoginWithAmazon',
  Apple = 'SignInWithApple',
  Cognito = 'COGNITO',
  Facebook = 'Facebook',
  Google = 'Google',
}

type SocialAuthButtonProps = {
  children: ReactNode;
  customState?: string;
  provider: CognitoHostedUIIdentityProvider;
};

const getProviderIcon = (
  provider: CognitoHostedUIIdentityProvider
): ComponentType<unknown> | null => {
  switch (provider) {
    case CognitoHostedUIIdentityProvider.Google:
      return GoogleIcon;
    case CognitoHostedUIIdentityProvider.Apple:
      return AppleIcon;
    default:
      return null;
  }
};

const SocialAuthButton = (props: SocialAuthButtonProps) => {
  const { clearAuthError, isLoading, setIsLoading } = useAuthContext();
  const { children, customState, provider } = props;

  if (!provider) {
    return null;
  }

  const showLoading =
    isLoading && provider === getItem(SessionStorageKeys.ClickedOAuthProvider);

  const handleSocialAuthClick = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setItem(SessionStorageKeys.ClickedOAuthProvider, provider);
    clearAuthError();
    setIsLoading(true);
    await AmplifyAuth.signInWithProvider({
      customState: customState ?? '{}', // It's necessary that we provide a customState so the the "customOAuthState" event gets triggered in AmplifyHub
      provider,
    });
  };

  const ProviderIcon = getProviderIcon(provider);

  return (
    <button className={styles.socialAuthButton} onClick={handleSocialAuthClick}>
      <div className={styles.icon}>{ProviderIcon && <ProviderIcon />}</div>
      {showLoading ? (
        <SpinnerInline size={BUTTON_SIZE.MEDIUM} />
      ) : (
        <span>{children}</span>
      )}
    </button>
  );
};

export default SocialAuthButton;
