import { useMutation } from '@apollo/client';

import { AUTH_REDIRECT_QUERY_PARAM, pageRoutes } from 'lib/routes';
import Logger from 'lib/utils/Logger';

import {
  CREATE_MULTIPASS_URL,
  CreateMultipassUrlResponse,
  CreateMultipassUrlVariables,
} from 'components/Buttons/CheckoutButton/CheckoutButton.gql';

type RedirectUrl = string | string[] | undefined;

const navigateToCheckout = (url: string) => {
  /**
   * Use window.location.href instead of NextJS Router.
   * This is necessary because 3rd party scripts are disabled on login and signup and we want to
   * force a full-page load so the scripts will load.
   */
  try {
    window.location.href = url;
  } catch (error) {
    Logger.error('Error navigate to checkout', error);
    window.location.href = '/error';
  }
};

type Return = {
  redirectAfterSuccessfulAuth(redirectUrl: RedirectUrl): Promise<void>;
};

/**
 * A utility for redirecting the user after they successfully login or signup
 * This hook will look inside of the URL query params for AUTH_REDIRECT_QUERY_PARAM
 * If one is present then it will redirect there. If the redirectUrl is pointing to
 * checkout, then a multipassurl is generated.
 */
export const useRedirectAfterAuth = (): Return => {
  const [createMultipassUrl] = useMutation<
    CreateMultipassUrlResponse,
    CreateMultipassUrlVariables
  >(CREATE_MULTIPASS_URL, {
    onError(error) {
      Logger.error('Error creating multipass url', error);
    },
  });

  const routeAuthenticatedUserToCheckout = async (
    guestCheckoutWebUrl: string
  ) => {
    try {
      const multipassResponse = await createMultipassUrl({
        variables: { input: { returnUrlPath: guestCheckoutWebUrl } },
      });

      const responseData = multipassResponse?.data?.createMultipassUrl;

      if (!responseData?.success) {
        throw Error(JSON.stringify(responseData?.validationErrors));
      }

      navigateToCheckout(responseData.multipassUrl);
    } catch (error) {
      Logger.error('Error with multipass', error);
    }
  };

  /**
   * Use window.location.href instead of NextJS Router.
   * This is necessary because 3rd party scripts are disabled on login and signup and we want to
   * force a full-page load so the scripts will load.
   */
  const redirectAfterSuccessfulAuth = async (redirectUrl: RedirectUrl) => {
    if (typeof redirectUrl === 'string') {
      if (redirectUrl.includes('checkout.verishop')) {
        routeAuthenticatedUserToCheckout(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }

      return;
    }

    window.location.href = pageRoutes.home.displayUrl();
  };

  return { redirectAfterSuccessfulAuth };
};

/**
 * Gets the redirect URL from the browser window object.
 *
 * We were not able to consistenty get the query params from useRouter()
 * during a server-side render so we resorted to this workaround.
 * It could be that this issue gets fixed in a newer version of NextJS.
 * The NextJS version at the time of this comment is v12.3.4
 */
export const getRedirectUrlFromWindow = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.get(AUTH_REDIRECT_QUERY_PARAM) ?? undefined;
};
