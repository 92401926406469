import { FederatedSignInOptions } from '@aws-amplify/auth/lib/types';
import get from 'lodash/get';

import getConfig from 'config/config';

import * as Auth from '../auth';

const getAmplifyAuth = async () => {
  const { Auth: AmplifyAuth } = await import('@aws-amplify/auth');

  return AmplifyAuth;
};

export const configureAmplify = async ({
  isSignupPage,
}: { isSignupPage?: boolean } = {}) => {
  const AmplifyAuth = await getAmplifyAuth();

  AmplifyAuth.configure({
    Auth: {
      // cookieStorage: {
      //   domain: 'localhost', // TODO auth: update with env var
      //   // expires: 365,
      //   path: '/',
      //   secure: false, // TODO auth: update with env var
      // },

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: ***,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      oauth: {
        clientId: getConfig('cognito.userPoolWebClientId'),
        domain: getConfig('cognito.domain'),
        redirectSignIn: isSignupPage
          ? getConfig('cognito.redirectSignInFromSignupPage')
          : getConfig('cognito.redirectSignInFromLoginPage'),
        redirectSignOut: getConfig('cognito.redirectSignOut'),
        responseType: 'code',
      },

      // REQUIRED - Amazon Cognito Region
      region: getConfig('cognito.region'),

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: getConfig('cognito.userPoolId'),
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: getConfig('cognito.userPoolWebClientId'),
    },
  });
};

export type CognitoError = Error & {
  code: string;
  message: string;
  name: string;
};

export type CognitoUser = {
  attributes: {
    email: string;
    family_name: string;
    given_name: string;
    identities?: string;
    sub: string; // Cognito userId
  };
};

const errorCodes = {
  expiredCode: 'ExpiredCodeException',
  invalidParameter: 'InvalidParameterException',
  limitExceeded: 'LimitExceededException',
  notAuthenticated: 'The user is not authenticated',
  userAlreadyExists: 'UsernameExistsException',
  userNotFound: 'UserNotFoundException',
};

export interface IChangePasswordProps {
  newPassword: string;
  oldPassword: string;
}

const changePassword = async ({
  newPassword,
  oldPassword,
}: IChangePasswordProps): Promise<string> => {
  return new Promise<'SUCCESS'>(async (resolve, reject) => {
    try {
      const currentUser = await Auth.getCurrentAuthenticatedUser();
      await Auth.changePassword(oldPassword, newPassword);
      await Auth.signOut({ global: true }); // signout all other sessions
      await signIn(currentUser.attributes.email, newPassword);
      resolve('SUCCESS');
    } catch (error) {
      reject(error);
    }
  });
};

const confirmSignUp = async (username: string, code: string) => {
  const AmplifyAuth = await getAmplifyAuth();
  return AmplifyAuth.confirmSignUp(username.toLowerCase(), code);
};

const sendVerificationCode = async (): Promise<void> => {
  const AmplifyAuth = await getAmplifyAuth();
  return AmplifyAuth.verifyCurrentUserAttribute('email');
};

const signIn = async (
  username: string,
  password: string
): Promise<CognitoUser> => {
  const AmplifyAuth = await getAmplifyAuth();
  const user = await AmplifyAuth.signIn(
    username.toLowerCase().trim(),
    password
  );
  await Auth.afterSignInHook();
  return user;
};

const signInWithProvider = async ({
  customState,
  provider,
}: FederatedSignInOptions): Promise<void> => {
  try {
    const AmplifyAuth = await getAmplifyAuth();
    await AmplifyAuth.federatedSignIn({ customState, provider });
  } catch (error) {
    const message =
      typeof error === 'string' ? error : get(error, 'message', '');
    throw new Error(`Federated SignIn error ${provider}: ${message}`);
  }
};

export type SignUpAttributes = {
  'custom:signup_referral_code'?: string;
  email: string;
};

const signUp = async (
  username: string,
  password: string,
  attributes: SignUpAttributes
) => {
  const AmplifyAuth = await getAmplifyAuth();
  attributes.email = attributes.email.toLowerCase().trim();
  const lowerCasedUsername = username.toLowerCase().trim();
  return AmplifyAuth.signUp({
    attributes,
    password,
    username: lowerCasedUsername,
  });
};

const sendForgotPasswordNotification = async (email: string) => {
  const AmplifyAuth = await getAmplifyAuth();
  return AmplifyAuth.forgotPassword(email.toLowerCase());
};

const resetPassword = async (
  email: string,
  code: string,
  newPassword: string
): Promise<void> => {
  const AmplifyAuth = await getAmplifyAuth();
  await AmplifyAuth.forgotPasswordSubmit(
    email.toLowerCase(),
    code,
    newPassword
  );
  await signIn(email.toLowerCase(), newPassword);
  await Auth.signOut({ global: true }); // signout all other sessions
};

export default {
  changePassword,
  confirmSignUp,
  errorCodes,
  resetPassword,
  sendForgotPasswordNotification,
  sendVerificationCode,
  signIn,
  signInWithProvider,
  signUp,
};
