import classnames from 'classnames';
import { useEffect, useState } from 'react';

import SocialAuthButton, {
  CognitoHostedUIIdentityProvider,
} from 'components/authForms/SocialAuthButton/SocialAuthButton';

import { useAuthContext } from 'lib/context/AuthContext';
import { getRedirectUrlFromWindow } from 'lib/hooks/auth/useRedirectAfterAuth/useRedirectAfterAuth';

import styles from './SocialAuthButtons.module.scss';

import sharedStyles from '../sharedStyles.module.scss';

const SocialAuthButtons = () => {
  const { error } = useAuthContext();
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);
  useEffect(() => {
    setRedirectUrl(getRedirectUrlFromWindow());
  }, []);

  const customState =
    typeof redirectUrl === 'string'
      ? JSON.stringify({ redirectUrl })
      : undefined;

  return (
    <div className={styles.socialAuthButtons}>
      <SocialAuthButton
        customState={customState}
        provider={CognitoHostedUIIdentityProvider.Google}
      >
        Continue with Google
      </SocialAuthButton>
      <SocialAuthButton
        customState={customState}
        provider={CognitoHostedUIIdentityProvider.Apple}
      >
        Continue with Apple
      </SocialAuthButton>
      {error && (
        <div className={classnames(sharedStyles.errorMessage, styles.ssoError)}>
          {error}
        </div>
      )}
      <div className={styles.lineWithText}>
        <span>OR</span>
      </div>
    </div>
  );
};

export default SocialAuthButtons;
